'use client';

import { explorationFeatureFlagAtom } from './exploration-feature-flag-atom';
import { ExplorationFlagsType } from './flags';
import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactNode } from 'react';

function HydrateFeatureFlagAtom({
  children,
  flags,
}: {
  children?: ReactNode;
  flags: ExplorationFlagsType;
}) {
  useHydrateAtoms([[explorationFeatureFlagAtom, flags]]);
  return children;
}

export const FeatureFlagProvider = ({
  children,
  flags,
}: {
  children: React.ReactNode;
  flags: ExplorationFlagsType;
}) => {
  return (
    <Provider>
      <HydrateFeatureFlagAtom flags={flags}>{children}</HydrateFeatureFlagAtom>
    </Provider>
  );
};
