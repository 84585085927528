import { getBeforeSend } from './beforeSend/getBeforeSend';
import { outlookSafeLinkFixBeforeSend } from './beforeSend/outlookSafeLinkFixBeforeSend';
import { isDatadogCategoryEnabled } from './isDatadogCategoryEnabled';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { v4 } from 'uuid';

export type DatadogRumUser = {
  id: string;
  name?: string;
  email?: string;
};

const checkUrlAllowed = (url: string) => {
  const loc = new URL(url);
  return (
    loc.protocol.toLowerCase() === 'https:' &&
    (loc.hostname.endsWith('aws.tfcloud.corp') ||
      loc.hostname.endsWith('aws.thrivent.com'))
  );
};

export function initializeDatadogRum({
  applicationId,
  clientToken,
  defaultPrivacyLevel = 'mask',
  env,
  service,
  sessionReplaySampleRate = 0, // Secure baseline: Do not capture user replay data in DataDog: https://thrivent.atlassian.net/browse/DOP-360
  useOneTrust = false,
  version,
}: {
  applicationId: string;
  clientToken: string;
  env: string;
  service: string;
  version: string;
  defaultPrivacyLevel?: 'mask' | 'allow' | 'mask-user-input';
  sessionReplaySampleRate?: number;
  useOneTrust?: boolean;
}): void {
  const isInIFrame =
    typeof window !== 'undefined' &&
    window?.location !== window?.parent?.location;

  if (isInIFrame) {
    // Do not initialize RUM when loading inside an iframe as this causes Views of these
    // pages to show up in user journeys
    // See: https://docs.icweb.stage.aws.tfcloud.corp/docs/adr/removing-iframe-pages-as-views
    return;
  }

  // Note that in all US jurisdictions, this will return true as category 2 is always enabled in those jurisdictions
  // As of 2025 we never enable categories 2-4 outside the of the US, so there's no need to check for updates
  // In other words, if the user loads with category 2 off, this code assumes it will never be turned on
  const trackingConsentGranted = !useOneTrust || isDatadogCategoryEnabled();
  datadogRum.init({
    trackingConsent: trackingConsentGranted ? 'granted' : 'not-granted',
    allowedTracingUrls: [
      { match: checkUrlAllowed, propagatorTypes: ['tracecontext'] },
    ],
    applicationId,
    beforeSend: getBeforeSend(),
    clientToken,
    defaultPrivacyLevel,
    enableExperimentalFeatures: ['feature_flags'],
    enablePrivacyForActionName: defaultPrivacyLevel === 'mask',
    env,
    excludedActivityUrls: [
      // Exclude health/liveliness checks
      /\/mgmt\/health\/liveness$/,
      /\/mgmt\/health\/readiness$/,
      /\/metrics$/,
    ],
    proxy: 'https://ddrp.apps.thrivent.com',
    service,
    sessionReplaySampleRate,
    trackSessionAcrossSubdomains: true,
    version,
  });

  if (typeof window !== 'undefined') {
    window.addEventListener('OneTrustGroupsUpdated', () => {
      const trackingConsentGranted = !useOneTrust || isDatadogCategoryEnabled();
      datadogRum.setTrackingConsent(
        trackingConsentGranted ? 'granted' : 'not-granted',
      );
    });
  }
}

export function initializeDatadogLogs({
  clientToken,
  env,
  service,
  version,
}: {
  clientToken: string;
  env: string;
  service: string;
  version: string;
}): void {
  datadogLogs.init({
    beforeSend: (event) => outlookSafeLinkFixBeforeSend(event),
    service,
    env,
    version,
    clientToken,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    trackSessionAcrossSubdomains: true,
    proxy: 'https://ddrp.apps.thrivent.com',
  });
}

export function setDefaultRumGlobalContext(): void {
  if (datadogRum.getInitConfiguration() === undefined) {
    return;
  }
  const service = datadogRum.getInitConfiguration()?.service;
  datadogRum.setGlobalContextProperty('tabId', v4());
  datadogRum.setGlobalContextProperty('service', service);
  datadogRum.setGlobalContextProperty('thrivent.team', service);
}

export function setRumUser(datadogUser: DatadogRumUser): void {
  if (datadogRum.getInitConfiguration() === undefined) {
    return;
  }
  datadogRum.setUser(datadogUser);
}

export function setGlobalContextFeatureFlags(
  flags: Record<string, string | number | boolean>,
): void {
  if (datadogRum.getInitConfiguration() === undefined) {
    return;
  }
  datadogRum.setGlobalContextProperty('feature_flags', flags);
}

export function parseIntOrUndefined(value: string = ''): number | undefined {
  const parsed = parseInt(value, 10);
  return isNaN(parsed) ? undefined : parsed;
}

export function parseBoolOrUndefined(
  value: string | undefined,
): boolean | undefined {
  if (value === undefined || !['true', 'false'].includes(value.toLowerCase())) {
    return undefined;
  }
  return value.toLowerCase() === 'true';
}
