import {
  VirtualAssistantChatAtom,
  VirtualAssistantChatWindow,
  trackAnalyticsLiveChatInteractionEventArgs,
  trackAnalyticsLiveChatNotificationEventArgs,
} from '../types';
import { Tracking } from '@thrivent-web/analytics';
import { logger } from '@thrivent-web/logging-utils';

// --------------------------------------------------------------------------

/**
 * loadVirtualAssistantEventHandlers
 *
 * Loads & binds all of the event handlers for the Virtual Assistant
 * List of documented events can be found here:
 * https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_chat_events.htm
 *
 * @returns function - (Event unloader)
 */
export const loadVirtualAssistantEventHandlers = (
  trackAnalyticsLiveChatNotificationEvent: ({
    type,
    data,
  }: trackAnalyticsLiveChatNotificationEventArgs) => void,
  trackAnalyticsLiveChatInteractionEvent: ({
    type,
    duration,
  }: trackAnalyticsLiveChatInteractionEventArgs) => void,
  setVirtualAssistantChat: (
    callback: (prev: VirtualAssistantChatAtom) => VirtualAssistantChatAtom,
  ) => void,
) => {
  const virtualAssistantChatWindow =
    global.window as VirtualAssistantChatWindow;
  if (!virtualAssistantChatWindow?.embedded_svc?.addEventHandler) {
    logger.error(
      'Tried to load Virtual Assistant Chat event handlers without embedded_svc object.',
      null,
    );
    return;
  }

  // For each event, we need to bind it to embedded_svc and then keep track of it in an array for unloading later
  const eventHandlers = [] as {
    name: string;
    handler: (data?: object | undefined) => void;
  }[];
  const pushToEventHandler = (
    eventHandlers: {
      name: string;
      handler?: (data?: object | undefined) => void;
    }[],
    name: string,
    handler?: (data?: object | undefined) => void,
  ) => {
    const virtualAssistantChatWindow =
      global.window as VirtualAssistantChatWindow;
    if (!virtualAssistantChatWindow?.embedded_svc?.addEventHandler) {
      logger.error(
        'Tried to load  Virtual Assistant Chat event handler without embedded_svc object.',
        null,
        { name },
      );
      return;
    }
    const defaultHandler = (data?: object) => {
      trackAnalyticsLiveChatNotificationEvent({
        type: name,
        data,
      });
    };
    const usedHandler = handler ? handler : defaultHandler;
    virtualAssistantChatWindow.embedded_svc.addEventHandler(name, usedHandler);
    eventHandlers.push({
      name,
      handler: usedHandler,
    });
  };

  // --- Notification Events ---
  [
    // Joining / Leaving
    'onAgentJoinedConference',
    'onAgentLeftConference',
    'onChatConferenceEnded',
    'onChatConferenceInitiated',
    'onChatReconnectSuccessful',
    // Transfers
    'onChatTransferInitiated',
    'onChatTransferSuccessful',
    // Errors
    'onConnectionError',
    'chasitorConnectionError',
    'chasitorChatRequestFailed',
    // Idle
    'onIdleTimeoutClear',
    'onIdleTimeoutOccurred',
    'onIdleTimeoutWarningStart',
    'onQueueUpdate',
    // Clicks in iframe (maybe?)
    'onClickSubmitButton',
    'onHelpButtonClick',
    'onInvitationResourceLoaded',
    'onInviteAccepted',
    'onInviteRejected',

    // Events below are here for reference, but were purposely commented out
    // for either being too noisy for analytics, or overall not useful
    // Talking
    // 'onAgentMessage',
    // 'onAgentRichMessage',
    // 'onChasitorMessage',
    // Called every 60s
    // 'onAvailability',
    // Init
    // 'onSettingsCallCompleted',
    // Potential other events
    // 'chasitorChatEstablished',
    // 'chasitorChatRequestSuccessful',
  ].forEach((eventName) => {
    pushToEventHandler(eventHandlers, eventName);
  });

  // --- User events ---

  const getChatDuration = () => {
    const startTime = virtualAssistantChatWindow?.embedded_svc?.chatStartTime;
    return startTime && !Number.isNaN(startTime)
      ? Date.now() - startTime
      : null;
  };

  // Open
  const openEvent = () => {
    setVirtualAssistantChat((prev) => ({
      ...prev,
      isLoading: false,
      isActive: true,
    }));
    trackAnalyticsLiveChatInteractionEvent({ type: Tracking.chatOpen });
    if (!virtualAssistantChatWindow?.embedded_svc) {
      return;
    }
    virtualAssistantChatWindow.embedded_svc.chatStartTime = Date.now();
  };
  pushToEventHandler(eventHandlers, 'onChatRequestSuccess', openEvent);

  // Start - !! as far as I can tell, there is no equivalent event here !!

  // Maximize
  const maximizeEvent = () => {
    // On page reload this looks to be the only event we get telling us the chat has re opened
    setVirtualAssistantChat((prev) => ({
      ...prev,
      isLoading: false,
      isActive: true,
    }));
    trackAnalyticsLiveChatInteractionEvent({ type: Tracking.chatMaximize });
  };
  pushToEventHandler(eventHandlers, 'afterMaximize', maximizeEvent);

  // Minimize
  const minimizeEvent = () => {
    setVirtualAssistantChat((prev) => ({
      ...prev,
      isLoading: false,
      isActive: true,
    }));
    trackAnalyticsLiveChatInteractionEvent({ type: Tracking.chatMinimize });
  };
  pushToEventHandler(eventHandlers, 'afterMinimize', minimizeEvent);

  // End
  const endEvent = () => {
    trackAnalyticsLiveChatInteractionEvent({
      type: Tracking.chatEnd,
      duration: getChatDuration(),
    });
  };
  pushToEventHandler(eventHandlers, 'onChatEndedByChasitor', endEvent);
  pushToEventHandler(eventHandlers, 'onChatEndedByAgent', endEvent);
  pushToEventHandler(eventHandlers, 'onChatEndedByChatbot', endEvent);
  pushToEventHandler(eventHandlers, 'chatbotEndedChat', endEvent);
  pushToEventHandler(eventHandlers, 'chatbotEndedChat', endEvent);
  pushToEventHandler(eventHandlers, 'chatbotEndedChat', endEvent);
  pushToEventHandler(eventHandlers, 'chatbotEndedChat', endEvent);
  pushToEventHandler(eventHandlers, 'chatbotEndedChat', endEvent);
  pushToEventHandler(eventHandlers, 'chatbotEndedChat', endEvent);

  // Close
  const closeEvent = () => {
    setVirtualAssistantChat((prev) => ({
      ...prev,
      isActive: false,
      isLoading: false,
    }));
    trackAnalyticsLiveChatInteractionEvent({
      type: Tracking.chatClose,
      duration: getChatDuration(),
    });
    if (!virtualAssistantChatWindow?.embedded_svc) {
      return;
    }
    virtualAssistantChatWindow.embedded_svc.chatStartTime = undefined;
  };
  pushToEventHandler(eventHandlers, 'afterDestroy', closeEvent);
};
