import { createGlobalStyle } from 'styled-components';

// BasisGrt
// Light
// https://cdn.thrivent.com/94/5b/5e58643e462385a9553a9e4b0a84/basisgrtforthrivent-lt.woff2
// Off White
// https://cdn.thrivent.com/e8/cb/8e3d2c604353a14b2f0dc15df139/basisgrtforthrivent-offwht.woff2
// Regular
// https://cdn.thrivent.com/76/87/0c96b7f94918a097a0c2024ba97a/basisgrtforthrivent.woff2
// Bold
// https://cdn.thrivent.com/78/5c/2112e694409494b79227563b4ccd/basisgrtforthrivent-bold.woff2
// Super Bold
// https://cdn.thrivent.com/2e/6a/e011f29a494789dbc4cb814c8325/basisgrtforthrivent-blk.woff2

// Burgess
// https://cdn.thrivent.com/a3/f2/ae6af68742beaed1dba18eb608ae/burgessforthrivent.woff2

export const GlobalStyleSheet = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  :root {
    --sans: 1em/1.6 ${(p) => p.theme.font.primary};
    --mono: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, /*  spellchecker: disable-line */
      'Courier New', monospace;
    --m1: 8px;
    --rc: 8px;
  }
  html {
    font-family: ${(p) => p.theme.font.primary};
    line-height: 1.5;
    tab-size: 4;
    scroll-behavior: smooth;
  }
  /* General settings */
  body {
    margin: 0;
    font: var(--sans);
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
    color: ${(p) => p.theme.midwestColors.textPrimary};
    font-family: inherit;
    line-height: inherit;
  }
  img,
  iframe {
    border: none;
    max-width: 100%;
  }
  a {
    font-family: ${(p) => p.theme.font.primary};
    font-size: 1rem;
    color: ${(p) => p.theme.midwestColors.textLink};
    text-decoration: none;
    cursor: pointer;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
  pre {
    font: 1em/1.6 var(--mono);
    background: ${(p) => p.theme.midwestColors.neutralLightStrong};
    padding: 1em;
    overflow: auto;
  }
  code {
    font: 1em/1.6 var(--mono);
  }
  blockquote {
    border-left: 5px solid ${(p) => p.theme.midwestColors.neutralLightStrong};
    padding: 1em 1.5em;
    margin: 0;
  }
  hr {
    border: 0;
    border-bottom: 1px solid ${(p) => p.theme.midwestColors.neutralDarkHeavy};
  }
  /* Headlines */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;  }
  input,
  select,
  textarea {
    font-size: 1em;
    color: ${(p) => p.theme.midwestColors.textPrimary};
    background: ${(p) => p.theme.midwestColors.neutralLightWeak};
    border: 0;
    padding: 0.6em;
  }
  input[type='submit'],
  input[type='reset'],
  input[type='button'] {
    font-size: 1.2em;
    font-weight: 900;
    display: inline-block;
    color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
    background: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
    border: 0;
    margin: 4px;
    padding: 1em;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
  p {
    margin: 0;
  }

@font-face {
  font-family: 'BasisGrt';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://cdn.thrivent.com/94/5b/5e58643e462385a9553a9e4b0a84/basisgrtforthrivent-lt.woff2) format('woff2');
}

@font-face {
  font-family: 'BasisGrt';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.thrivent.com/94/5b/5e58643e462385a9553a9e4b0a84/basisgrtforthrivent-lt.woff2) format('woff2');
}

@font-face {
  font-family: 'BasisGrt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.thrivent.com/76/87/0c96b7f94918a097a0c2024ba97a/basisgrtforthrivent.woff2) format('woff2');
}

@font-face {
  font-family: 'BasisGrt';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.thrivent.com/78/5c/2112e694409494b79227563b4ccd/basisgrtforthrivent-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'BasisGrt';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.thrivent.com/78/5c/2112e694409494b79227563b4ccd/basisgrtforthrivent-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'BasisGrt';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.thrivent.com/78/5c/2112e694409494b79227563b4ccd/basisgrtforthrivent-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Burgess';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.thrivent.com/a3/f2/ae6af68742beaed1dba18eb608ae/burgessforthrivent.woff2) format('woff2');
}
`;

export const ExplorationBrandRefreshGlobalStyle = () => <GlobalStyleSheet />;
