import { virtualAssistantChatAtom } from './virtual-assistant-chat-atom';
import { useAtom } from 'jotai';

// --------------------------------------------------------------------------

export const useVirtualAssistantChat = () => {
  const [virtualAssistantChat, setVirtualAssistantChat] = useAtom(
    virtualAssistantChatAtom,
  );
  const { isInitialized, hasError, isLoading, isActive } = virtualAssistantChat;
  const isEnabled = !hasError && isInitialized;

  return {
    setVirtualAssistantChat,
    isEnabled,
    isInitialized,
    hasError,
    isLoading,
    isActive,
  };
};
