import {
  trackAnalyticsLiveChatInteractionEventArgs,
  trackAnalyticsLiveChatNotificationEventArgs,
} from '../types';
import { Tracking, useAnalyticsTracking } from '@thrivent-web/analytics';

// --------------------------------------------------------------------------

/**
 * useVirtualAssistantChatAnalytics
 *
 * Hook that returns 2 analytics tracking event functions
 */
export const useVirtualAssistantChatAnalytics = () => {
  const { trackEvent } = useAnalyticsTracking();

  // Used for any of the automatic script events
  const trackAnalyticsLiveChatNotificationEvent = ({
    type,
  }: trackAnalyticsLiveChatNotificationEventArgs) => {
    trackEvent({
      eventType: Tracking.notification,
      eventInfo: {
        notificationName: 'chat',
        notificationVariant: type,
      },
    });
  };

  // Used for any events that are caused from some type of user interaction
  const trackAnalyticsLiveChatInteractionEvent = ({
    type,
    duration,
  }: trackAnalyticsLiveChatInteractionEventArgs) => {
    trackEvent({
      eventType: type,
      eventInfo: {
        duration: duration ?? null,
      },
    });
  };

  return {
    trackAnalyticsLiveChatInteractionEvent,
    trackAnalyticsLiveChatNotificationEvent,
  };
};
