import { VirtualAssistantChatAtom } from './types';
import { atom } from 'jotai';

// --------------------------------------------------------------------------

/**
 * virtualAssistantChatAtom
 *
 * Toggles the various states the salesforce script / chat can be in
 *
 * @returns PrimitiveAtom<LiveChatAtom>
 */
export const virtualAssistantChatAtom = atom<VirtualAssistantChatAtom>({
  isInitialized: false,
  hasError: false,
  isLoading: false,
  isActive: false,
});
