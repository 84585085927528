'use client';

import { findAdobeWithRetry } from './findAdobeWithRetry';
import { pushToAdobeDataLayer } from './pushToAdobeDataLayer';
import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsProvider, TrackingEvent } from '@thrivent-web/analytics';
import { logger } from '@thrivent-web/logging-utils';
import { ReactElement } from 'react';

const onDispatch = (data: TrackingEvent) => {
  try {
    datadogRum.addAction('analytics', data);
    findAdobeWithRetry(5000, () => pushToAdobeDataLayer(data));
  } catch (err) {
    logger.warn('There was an error dispatching analytics events', err);
  }
};

export const AdobeAnalytics = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  return (
    <AnalyticsProvider dispatch={onDispatch}>{children}</AnalyticsProvider>
  );
};
