'use client';

import {
  SearchHeadlessProvider,
  provideHeadless,
} from '@yext/search-headless-react';

const getSearchProvider = (
  apiKey: string,
  locale: string,
  verticalKey: string,
) => {
  return provideHeadless({
    apiKey,
    experienceKey: 'locator',
    locale,
    verticalKey: verticalKey,
    experienceVersion:
      process.env['ENVIRONMENT'] === 'prod' ? 'PRODUCTION' : 'STAGING',
    // endpoints: SandboxEndpoints // Add if using a sandbox account
  });
};

export const YextSearchProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const searcher = getSearchProvider(
    process.env.NEXT_PUBLIC_YEXT_SEARCH_API_KEY ?? '',
    'en',
    'locations',
  );

  return (
    <SearchHeadlessProvider searcher={searcher}>
      {children}
    </SearchHeadlessProvider>
  );
};
