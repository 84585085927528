'use client';

import {
  InitializeDatadogComponent,
  InitializeDatadogProps,
  getProcessDefaultPrivacyLevel,
  initializeDatadogLogs,
  initializeDatadogRum,
  parseBoolOrUndefined,
  parseIntOrUndefined,
} from '@analytics/datadog/utils';

if (process.env['NEXT_PUBLIC_DATADOG_RUM'] === 'true') {
  initializeDatadogRum({
    applicationId: process.env['NEXT_PUBLIC_DD_APP_ID']!,
    clientToken: process.env['NEXT_PUBLIC_DD_CLIENT_TOKEN']!,
    env: process.env['NEXT_PUBLIC_TRACE_ENV'] || 'none',
    service: process.env['NEXT_PUBLIC_TRACE_SERVICE']!,
    version: process.env['NEXT_PUBLIC_TRACE_VERSION'] || 'none',
    defaultPrivacyLevel: getProcessDefaultPrivacyLevel() || 'mask',
    sessionReplaySampleRate: parseIntOrUndefined(
      process.env['NEXT_PUBLIC_DD_SESSION_REPLAY_RATE'],
    ),
    useOneTrust: parseBoolOrUndefined(
      process.env['NEXT_PUBLIC_DD_USE_ONE_TRUST'],
    ),
  });
}

if (process.env['NEXT_PUBLIC_DATADOG_BROWSER_LOGS'] === 'true') {
  initializeDatadogLogs({
    clientToken: process.env['NEXT_PUBLIC_DD_CLIENT_TOKEN']!,
    env: process.env['NEXT_PUBLIC_TRACE_ENV'] || 'none',
    service: process.env['NEXT_PUBLIC_TRACE_SERVICE']!,
    version: process.env['NEXT_PUBLIC_TRACE_VERSION'] || 'none',
  });
}

// Even though this component renders only the generic component, don't export
// the generic component directly otherwise production build will remove the
// above initialization code.
export const InitializeDatadog = (props: InitializeDatadogProps) => (
  <InitializeDatadogComponent {...props} />
);
