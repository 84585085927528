import { VirtualAssistantChatWindow } from '../types';
import { logger } from '@thrivent-web/logging-utils';

// --------------------------------------------------------------------------

/**
 * initEmbeddedVirtualAssistantChat
 *
 * Initializes the SalesForce Legacy Chat
 *
 * @returns boolean
 */
export const initEmbeddedVirtualAssistantChat = (
  baseUrl: string | null,
  utmData?: {
    utmSource: string | undefined;
    sourceUrl: string | undefined;
    visitorId: string;
  },
) => {
  try {
    // Set our settings
    const virtualAssistantChatWindow =
      global.window as VirtualAssistantChatWindow;
    if (!virtualAssistantChatWindow?.embedded_svc?.settings) {
      virtualAssistantChatWindow.embedded_svc = {
        ...(virtualAssistantChatWindow.embedded_svc ?? {}),
      };
      virtualAssistantChatWindow.embedded_svc.settings = {};
    }

    virtualAssistantChatWindow.embedded_svc.settings.fallbackRouting = [
      'sf-virtual-assistant-chat-launch',
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_BUTTON_ID'] ?? '',
    ];
    virtualAssistantChatWindow.embedded_svc.settings.displayHelpButton = false;
    virtualAssistantChatWindow.embedded_svc.settings.language = '';
    virtualAssistantChatWindow.embedded_svc.settings.enabledFeatures = [
      'LiveAgent',
    ];
    virtualAssistantChatWindow.embedded_svc.settings.entryFeature = 'LiveAgent';
    virtualAssistantChatWindow.embedded_svc.settings.offlineSupportMinimizedText =
      'Virtual Assistant';
    virtualAssistantChatWindow.embedded_svc.settings.defaultMinimizedText =
      'Virtual Assistant';
    virtualAssistantChatWindow.embedded_svc.settings.disabledMinimizedText =
      'Virtual Assistant';
    virtualAssistantChatWindow.embedded_svc.settings.defaultAssistiveText =
      'Virtual Assistant:';

    // Attach analytics IDs and utm/source url
    virtualAssistantChatWindow.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: 'visitorId',
        value: utmData?.visitorId,
        displayToAgent: false,
        transcriptFields: ['AdobeVisitorId__c'],
      },
      {
        label: 'utmSourceCode',
        value: utmData?.utmSource,
        displayToAgent: false,
        transcriptFields: ['UtmSourceCode__c'],
      },
      {
        label: 'sourceUrl',
        value: utmData?.sourceUrl,
        displayToAgent: false,
        transcriptFields: ['SourceUrl__c'],
      },
    ];

    // Bail if we got here without an init function
    if (!virtualAssistantChatWindow?.embedded_svc?.init) {
      logger.error(
        'Virtual Assistant Chat called with no init available',
        null,
        {
          baseUrl,
        },
      );
      return false;
    }

    // Grab our metric ton of SF specific URLs/IDs
    const sfUrl = process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_SF_URL'];
    const sfChatUrl =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_SF_CHAT_URL'];
    const organizationId =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_ORG_ID'];
    const name = process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_NAME'];
    const baseLiveAgentURL =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_LIVE_AGENT_URL'];
    const baseLiveAgentContentURL =
      process.env[
        'NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_LIVE_AGENT_CONTENT_URL'
      ];
    const deploymentId =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_DEPLOYMENT_ID'];
    const buttonId =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_BUTTON_ID'];
    const eswLiveAgentDevName =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_LIVE_AGENT_DEV_NAME'];

    const isOfflineSupportEnabled =
      !!process.env[
        'NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_OFFLINE_SUPPORT_ENABLED'
      ];

    // Bail with bad env vars
    if (
      !sfUrl ||
      !sfChatUrl ||
      !organizationId ||
      !name ||
      !baseLiveAgentURL ||
      !baseLiveAgentContentURL ||
      !deploymentId ||
      !buttonId ||
      !eswLiveAgentDevName
    ) {
      logger.error('Virtual Assistant Chat missing required fields', null, {
        sfUrl,
        sfChatUrl,
        baseUrl,
        organizationId,
        name,
        baseLiveAgentURL,
        baseLiveAgentContentURL,
        deploymentId,
        buttonId,
        eswLiveAgentDevName,
        isOfflineSupportEnabled,
      });
      return false;
    }
    virtualAssistantChatWindow.embedded_svc.init(
      sfUrl,
      sfChatUrl,
      baseUrl,
      organizationId,
      name,
      {
        baseLiveAgentURL,
        baseLiveAgentContentURL,
        deploymentId,
        buttonId,
        eswLiveAgentDevName,
        isOfflineSupportEnabled,
      },
    );
    return true;
  } catch (e) {
    logger.error('Caught error: init Virtual Assistant Chat', e, baseUrl);
    return false;
  }
};
