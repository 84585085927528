'use client';

import { VirtualAssistantChatWindow } from './types';
import { useVirtualAssistantChat } from './use-virtual-assistant-chat';
import { initEmbeddedVirtualAssistantChat } from './utils/init-embedded-virtual-assistant-chat';
import { loadVirtualAssistantEventHandlers } from './utils/load-virtual-assistant-event-handlers';
import { useVirtualAssistantChatAnalytics } from './utils/use-virtual-assistant-chat-analytics';
import { logger } from '@thrivent-web/logging-utils';
import { useEffect } from 'react';
import { useLeadFormContextData } from 'visitor-attributes-react';

// --------------------------------------------------------------------------

export const VirtualAssistantChatScript = () => {
  const {
    trackAnalyticsLiveChatNotificationEvent,
    trackAnalyticsLiveChatInteractionEvent,
  } = useVirtualAssistantChatAnalytics();
  const { setVirtualAssistantChat } = useVirtualAssistantChat();
  const { utmSourceDTM, referenceCodeURL, visitorId } =
    useLeadFormContextData();

  // UseEffect for loading the script
  useEffect(() => {
    const virtualAssistantChatWindow =
      global.window as VirtualAssistantChatWindow;
    // Don't load the script if targeting cookies are disabled
    if (
      !virtualAssistantChatWindow.OnetrustActiveGroups ||
      !virtualAssistantChatWindow.OnetrustActiveGroups.includes('4')
    ) {
      logger.info('Virtual Assistant: Incorrect Optanon category');
      return;
    }

    const existingScript = document.getElementById('sf-virtual-assistant-chat');
    if (existingScript) {
      return;
    }

    const scriptSrc =
      process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_SCRIPT_SRC'];
    if (!scriptSrc) {
      logger.error('Virtual Assistant: No script source provided.', null);
      return;
    }

    const script = document.createElement('script');
    script.src = scriptSrc;
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = 'sf-virtual-assistant-chat';
    script.dataset['testid'] = 'sf-virtual-assistant-chat';
    script.onload = () => {
      const virtualAssistantChatWindow =
        global.window as VirtualAssistantChatWindow;

      // Per the docs event handlers must be called BEFORE init
      loadVirtualAssistantEventHandlers(
        trackAnalyticsLiveChatNotificationEvent,
        trackAnalyticsLiveChatInteractionEvent,
        setVirtualAssistantChat,
      );

      const baseUrl =
        process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_BASE_URL'] ??
        'NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_BASE_URL_NOT_SET';

      // Per the snippet, if embedded_svc is present
      // We're just supposed to call init but with a url
      // Otherwise pass null
      const init = initEmbeddedVirtualAssistantChat(
        !!virtualAssistantChatWindow.embedded_svc ? baseUrl : null,
        { utmSource: utmSourceDTM, sourceUrl: referenceCodeURL, visitorId },
      );
      if (!init) {
        setVirtualAssistantChat((prev) => ({
          ...prev,
          hasError: true,
        }));
        return;
      }

      setVirtualAssistantChat((prev) => ({
        ...prev,
        isInitialized: true,
      }));
    };
    document.body.appendChild(script);
  }, [
    referenceCodeURL,
    setVirtualAssistantChat,
    trackAnalyticsLiveChatInteractionEvent,
    trackAnalyticsLiveChatNotificationEvent,
    utmSourceDTM,
    visitorId,
  ]);
  return null;
};
